<template>
  <div class="admin-list">
    <h4 class="list-title">{{ $lang.app.groups }}</h4>
    <div class="admin-navbar align-right">
      <router-link v-if="creatable" to="/admin/groups/create" class="action-button">{{ $lang.app.create }}</router-link>
    </div>
    <div class="admin-table">
      <table>
        <thead>
        <tr>
          <th v-if="$store.getters.isPreschool"
              class="key">{{ $lang.app.title }}</th>
          <th v-if="$store.getters.isPreschool">{{ $lang.app.category }}</th>
          <th :class="{'key': $store.getters.isSchool}">{{ $lang.app.education_language }}</th>
          <th>{{ $lang.app.position_quantity }}</th>
          <th>{{ $lang.app.enrolled }}</th>
          <th><div v-html="$lang.app.available_quotas"></div></th>
          <th>{{ $lang.app.waiting_enrolling }}</th>
          <th>{{ $lang.app.waiting }}</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="group in groups" :key="group.id">
          <td v-if="$store.getters.isPreschool"
              class="key">{{ group.name }}</td>
          <td v-if="$store.getters.isPreschool">
            <span v-if="group.preschoolGroupType">
              {{ $lang.$translate({ru: group.preschoolGroupType.nameRu, kg: group.preschoolGroupType.nameKg}) }}
            </span>
          </td>
          <td :class="{'key': $store.getters.isSchool}">{{ group.language.name }}</td>
          <td>{{ group.quota }}</td>
          <td>{{ group.enrolled }}</td>
          <td>{{ group.freeQuota }}</td>
          <td>{{ group.waitingEnrolling }}</td>
          <td>{{ group.waiting }}</td>
          <td>
            <div class="action-buttons">
              <button v-if="transferable" class="action-button transfer-button" :title="$lang.app.transfer_students"
                      @click="transferRequest(group.id)"><i class="bi-arrow-right-square"></i></button>
<!--              <router-link :to="`/admin/groups/${group.id}`"-->
<!--                           class="action-button" :title="$lang.app.view"><i class="bi-info-square"></i></router-link>-->
              <router-link v-if="editable" :to="`/admin/groups/${group.id}/edit`"
                           class="action-button" :title="$lang.app.edit"><i class="bi-pencil-square"></i></router-link>
              <button v-if="deletable" class="action-button" :title="$lang.app.delete" @click="deleteRequest(group.id)"><i class="bi-x-square"></i></button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <DeleteModal v-if="deleteId"
                 @close="deleteId = null"
                 @delete="deleteResource"
    />
    <TransferModal v-if="transferId"
                   :group-id="transferId"
                   :groups="groups"
                   @close="transferId = null"
                   @refresh="() => {
                      transferId = null
                      fetchResources()
                   }"/>
  </div>
</template>
<script>
import DeleteModal from '../../../components/admin/DeleteModal'
import TransferModal from '../../../components/admin/group/TransferModal'
export default {
  components: {
    DeleteModal,
    TransferModal,
  },
  data() {
    return {
      groups: [],
      deleteId: null,
      transferId: null,
    }
  },
  computed: {
    creatable() {
      return this.$store.getters.isAdmin || this.$store.getters.isPreschool
    },
    transferable() {
      return this.$store.getters.isAdmin || this.$store.getters.isPreschool
    },
    editable() {
      return this.$store.getters.isAdmin || this.$store.getters.isPreschool
    },
    deletable() {
      return this.$store.getters.isAdmin || this.$store.getters.isPreschool
    }
  },
  watch: {
    '$route'(value, oldValue) {
      if(value.path === oldValue.path) {
        this.fetchResources()
      }
    }
  },
  methods: {
    fetchResources() {
      this.$axios.get(`/organization-group/organization/${this.$store.getters.getOrganizationId}`)
          .then(({data}) => {
            this.groups = data
          })
    },
    deleteRequest(id) {
      this.deleteId = id
    },
    transferRequest(id) {
      this.transferId = id
    },
    deleteResource() {
      this.$axios.delete(`/organization-group/${this.deleteId}`).then(() => {
        this.fetchResources()
        this.$snotify.success(this.$lang.app.resource_deleted_successfully);
      }).finally(() => {
        this.deleteId = null
      })
    }
  },
  mounted() {
    this.fetchResources()
  }
}
</script>
<style>
@import '../../../assets/styles/admin/list.css';
@import '../../../assets/styles/admin/navbar.css';
@import '../../../assets/styles/admin/table.css';
.admin-table button.transfer-button {
  color: orange;
}
</style>