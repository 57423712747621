<template>
  <div class="modal">
    <div class="modal-background" @click="$emit('close')"></div>
    <div class="modal-content">
      <div class="service-error">
        <div class="service-error-title">
          <i class="bi-exclamation-triangle-fill"></i>
          <p>{{ $lang.app.delete_submission_text }}</p>
        </div>
        <div class="controls">
          <button @click="$emit('delete')">{{ $lang.app.yes }}</button>
        </div>
      </div>
    </div>
    <button class="modal-close" @click="$emit('close')"><i class="bi-x-circle"></i></button>
  </div>
</template>
<style>
@import '../../assets/styles/modal.css';
</style>